<template>
    <div>
        <!-- Start Header Area -->
        <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile" />
        <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none" />
        <!-- End Header Area -->

        <!-- Start Contact Area  -->
        <div class="rn-contact-area rn-section-gap bg_color--5">
            <div class="contact-form--1">
                <v-container>

                    <div class="container">
                        <div class="section-title text-left mb--50 mb_sm--30 mb_md--30">
                            <div style="display: flex; flex-direction: row;">
                                <h2 class="heading-title">News</h2>
                                <v-spacer></v-spacer>
                                <v-btn-toggle v-model="toggleSort">
                                    <v-btn>
                                        <v-icon>
                                            {{ 'mdi-sort-ascending' }}
                                        </v-icon>
                                    </v-btn>
                                    <v-btn>
                                        <v-icon>
                                            {{ 'mdi-sort-descending' }}
                                        </v-icon>
                                    </v-btn>
                                </v-btn-toggle>
                            </div>
                            <p class="description">
                                Things happening that surrounds our company
                            </p>
                            <div v-bind:key="index" v-for="(item, index) in sortedNewsItem">
                                <v-list-item v-on:click="openALink(item.link)">
                                    <v-row no-gutters style="margin-top:20px; margin-bottom: 20px;">
                                        <v-col md="3" style="padding: 0px !important; margin: 0px !important;">
                                            <v-img style="box-shadow: 0px 0px 10px 1px orange; max-width: 600px;"
                                                :src="item.img" aspect-ratio="4"></v-img>
                                        </v-col>
                                        <v-col md="9">
                                            <div class="pl-7">
                                                <v-row>
                                                    <v-col>
                                                        <span> {{ item.date }} </span>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <span class="headline"
                                                            style="display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; text-align: left;">
                                                            {{ item.title }} </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-list-item>
                                <v-divider></v-divider>
                            </div>
                        </div>
                    </div>
                </v-container>
            </div>
        </div>
        <!-- End Contact Area  -->

        <Footer />
    </div>
</template>
  
<script>
import HeaderOnePageTwo from "../../src/components/header/HeaderOnePageTwoSiber.vue";
import HeaderOnePageTwoMobile from "../../src/components/header/HeaderOnePageTwoSiberMobile.vue";
import Footer from "../components/footer/Footer.vue";
export default {
    components: {
        HeaderOnePageTwo,
        HeaderOnePageTwoMobile,
        Footer,
    },
    computed: {
        sortedNewsItem() {
            if (this.toggleSort == 0) {

                return this.newsItem.sort((a, b) => {
                    const date1 = new Date(a.date.split('/').reverse().join('/'))
                    const date2 = new Date(b.date.split('/').reverse().join('/'))
                    return date2 - date1
                })
            } else if (this.toggleSort == 1) {

                return this.newsItem.sort((a, b) => {
                    const date1 = new Date(a.date.split('/').reverse().join('/'))
                    const date2 = new Date(b.date.split('/').reverse().join('/'))
                    return date1 - date2
                })
            }
        }
    },
    data() {
        return {
            toggleSort: 0,
            newsItem: [
                {
                    company: 'Detik Finance',
                    title: 'Bank Ramai-ramai Mulai Manfaatkan \'Robot\' buat Pelayanan',
                    date: '28/09/2022',
                    link: 'https://finance.detik.com/berita-ekonomi-bisnis/d-6317590/bank-ramai-ramai-mulai-manfaatkan-robot-buat-pelayanan',
                    img: require('../assets/images/service/news/logo-detikfinance.png'),
                },
                {
                    company: 'Media Indonesia',
                    title: 'Perbankan Berlomba Tingkatkan Layanan Lewat Digitalisasi dan Kecerdasan Buatan',
                    date: '28/09/2022',
                    link: 'https://m.mediaindonesia.com/ekonomi/526090/perbankan-berlomba-tingkatkan-layanan-lewat-digitalisasi-dan-kecerdasan-buatan',
                    img: require('../assets/images/service/news/mediaindonesia.png'),
                },
                {
                    company: 'Katadata',
                    title: 'Teknologi di Balik Livin’ Bank Mandiri: Atasi Ratusan Juta Transaksi',
                    date: '28/09/2022',
                    link: 'https://katadata.co.id/desysetyowati/digital/63342a5705ada/teknologi-di-balik-livin-bank-mandiri-atasi-ratusan-juta-transaksi',
                    img: require('../assets/images/service/news/Katadata.png'),
                },
                {
                    company: 'Katadata',
                    title: 'Perbankan Tingkatkan Pelayanan Melalui Digital dan AI',
                    date: '28/09/2022',
                    link: 'https://katadata.co.id/doddyrosadi/berita/6333fe3aa8eb1/perbankan-tingkatkan-pelayanan-melalui-digital-dan-ai',
                    img: require('../assets/images/service/news/Katadata.png'),
                },
                {
                    company: 'Antara News',
                    title: 'Perbankan Mendorong Nasabah berubah dari Konvensional ke Digital',
                    date: '28/09/2022',
                    link: 'https://mataram.antaranews.com/berita/221553/perbankan-mendorong-nasabah-berubah-dari-konvensional-ke-digital',
                    img: require('../assets/images/service/news/Logo-ANTARANews.png'),
                },
                {
                    company: 'Marketing.co.id',
                    title: 'Begini Cara Bank Himbara Meningkatkan Pelayanan di Era Digital',
                    date: '29/09/2022',
                    link: 'https://www.marketing.co.id/perbankan-tingkatkan-pelayanan-melalui-digitalisasi-dan-artificial-intelligence/',
                    img: require('../assets/images/service/news/logomarketingcoid.jpg'),
                },
                {
                    company: 'Kompas',
                    title: 'Pemanfaatan Data "Real Time" Mampu Tingkatkan Daya Saing Perusahaan',
                    date: '30/09/2022',
                    link: 'https://money.kompas.com/read/2022/09/30/070000126/pemanfaatan-data-real-time-mampu-tingkatkan-daya-saing-perusahaan',
                    img: require('../assets/images/service/news/kompas.jpg')
                },
                {
                    company: 'Medcom',
                    title: 'Ingat! Perusahaan Butuh Active Intelligence untuk Tingkatkan Daya Saing',
                    date: '29/09/2022',
                    link: 'https://www.medcom.id/ekonomi/bisnis/GKd2EX0b-ingat-perusahaan-butuh-active-intelligence-untuk-tingkatkan-daya-saing',
                    img: require('../assets/images/service/news/Medcom_(2019).png')
                },
                {
                    company: 'Kontan',
                    title: 'Persaingan Meningkat, Perusahaan Bisa Menerapkan Active Intelligence',
                    date: '30/09/2022',
                    link: 'https://industri.kontan.co.id/news/persaingan-meningkat-perusahaan-bisa-menerapkan-active-intelligence',
                    img: require('../assets/images/service/news/kontan.png')
                },
                {
                    company: 'Investor.id',
                    title: 'Active Intelligence Dinilai Mampu Tingkatkan Daya Saing Perusahaan',
                    date: '1/10/2022',
                    link: 'https://investor.id/it-and-telecommunication/308605/active-intelligence-dinilai-mampu-tingkatkan-daya-saing-perusahaan',
                    img: require('../assets/images/service/news/investorLogo.jpg')
                },
                {
                    company: 'Republika',
                    title: 'Dukung Transformasi, Perbankan Dorong Nasabah Berubah dari Konvensional ke Digital',
                    date: '28/09/2022',
                    link: 'https://ekonomi.republika.co.id/berita//rixfxv456/dukung-transformasi-perbankan-dorong-nasabah-berubah-dari-konvensional-ke-digital',
                    img: require('../assets/images/service/news/logo-republika.jpg')
                },
                {
                    company: 'Jawapos',
                    title: 'Upaya Pacu Daya Saing Perusahaan Lewat Pemanfaatan Active Intelligence ',
                    date: ' 29/09/2022',
                    link: 'https://www.jawapos.com/teknologi/01410701/upaya-pacu-daya-saing-perusahaan-lewat-pemanfaatan-active-intelligence',
                    img: require('../assets/images/service/news/jawapos.png')
                },
                {
                    company: 'Qlik (logo qlik)',
                    title: 'Qlik Announces Recipients of its Global and Regional Partner Awards',
                    date: '12/05/2021',
                    link: 'https://www.qlik.com/blog/qlik-announces-recipients-of-its-global-and-regional-partner-awards',
                    img: require('../assets/images/service/principal/qlik.png')
                },
                {
                    company: 'Antara',
                    title: 'Kemenhub luncurkan sistem integrasi kenavigasian I-Motion',
                    date: '1/12/2021',
                    link: 'https://www.antaranews.com/berita/2558921/kemenhub-luncurkan-sistem-integrasi-kenavigasian-i-motion',
                    img: require('../assets/images/service/news/Logo-ANTARANews.png')
                }
            ]
        };
    },
    methods: {
        openALink(link) {
            window.open(link, '_blank')
        }
    }
};
</script>
<style scoped>
.container {
    display: block;
    margin: auto;
    text-align: left;
    border-radius: 10px;
    background-color: #ffff;
    padding: 50px;
}
</style>
  